<template>
  <div class="loginBgd">
    <div class="loginArea">
      <div class="loginleft">
        <div class="loginlefttop"></div>
        <h2>ABC数码科技</h2>
        <h2>维修管理系统</h2>
        <div class="loginleftbottom"></div>
      </div>
      <div class="loginright">
          <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style scoped lang="less">
.loginBgd {
  height: 100%;
  width: 100%;
  background-image: url("~@/assets/image/Login/loginBgd.png");
  background-size:100% 100%;
}
.loginArea {
  height: 80%;
  width: 55%;
  background-color: rgba(22, 39, 92, 0.5);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loginleft {
  float: left;
  width: 52%;
  height: 100%;
}
.loginright {
  float: right;
  width: 48%;
  height: 100%;
  background-color: #fff;
}
.loginlefttop {
  width: 100%;
  height: 25%;
  background-image: url("~@/assets/image/Login/HELLO.png");
  background-size:35% 18%;
  background-position: 18% 85%;
}
h2 {
  color: #fff;
  font-weight: 700;
  font-size: 3rem;
  text-indent: 10%;
  line-height: 200%;
}
.loginleftbottom {
  width: 100%;
  height: calc(75% - 12rem);
  background-image: url("~@/assets/image/Login/LOGO.png");
  background-size:40% 60%;
  background-position: 18% 60%;
}
</style>
