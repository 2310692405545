<template>
  <LoginStyle1>
    <div class="logincontent">
      <h6>欢迎您，选择<em>ABC智能管理系统</em></h6>
      <p class="LeftLogin">登录</p>
      <p @click="GoRegister">注册</p>
      <!-- <div>
        <label for="ProductType">产品:</label><br />
        <select id="ProductType" v-model="LoginViewModelAdd.ProductTypeName">
          <option value="ForRepair">维修行业</option>
        </select>
      </div> -->
      <div class="contentContainer">
        <div>
          <label for="CompanyNameAbbre">店铺名称:</label><br />
          <input id="CompanyNameAbbre" type="text" placeholder="请输入店铺名" v-model="LoginViewModelAdd.CompanyNameAbbre" @click="changeActiveInput('CompanyNameAbbre')" @blur="changeActiveInput('')" />
        </div>
        <DropDownClick :dropDownContent="userList" topValue="3.5rem" widthValue="80%" v-if="activeInput == 'CompanyNameAbbre' && userList.length > 0" @handle="fillUserInfo"></DropDownClick>
      </div>
      <div class="contentContainer">
        <div>
          <label for="UserName">用户名:</label><br />
          <input id="UserName" type="text" placeholder="请输入用户名" v-model="LoginViewModelAdd.UserName" @click="changeActiveInput('UserName')" @blur="changeActiveInput('')" />
        </div>
        <DropDownClick :dropDownContent="userList" topValue="3.5rem" widthValue="80%" v-if="activeInput == 'UserName' && userList.length > 0" @handle="fillUserInfo"></DropDownClick>
      </div>
      <div>
        <label for="Password">密码:</label><br />
        <input id="Password" type="password" placeholder="请输入密码" v-model="LoginViewModelAdd.Password" />
      </div>
      <div class="remempassword">
        <input type="checkbox" id="rembpw" v-model="whetherSaveData" />
        <label class="checkAfter" for="rembpw">记住密码 </label>
      </div>
      <div>
        <input class="loginsubmit" type="submit" value="登陆" @click.prevent="LoginAdd()" />
      </div>
      <div class="copyRight">Copyright@ 2020-2024 南京简易云联电子商务有限公司 <a target="_blank" href="http://beian.miit.gov.cn">苏ICP备2020068850号-4</a><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32010202011292"><img src="../../src/assets/image/Login/beian.png"/>苏公网安备 32010202011292号</a></div>
    </div>
    <div class="popWindowcloak" v-show="$store.state.netloading">
      <img class="loadingimg" src="../../src/assets/image/Padding/loading1.gif" alt="加载中">
    </div>
  </LoginStyle1>
</template>

<script>
import LoginStyle1 from "@/components/Common/LoginStyle1.vue";
import DropDownClick from "@/components/Common/DropDownClick.vue";
import { reactive, ref } from "@vue/reactivity";
import router from "@/router/index.js";
import { loginInitializeCheck, loginInitialize, getUserInfo, getAuthoList } from "@/hooks/loginAffair";
import { copyObj } from "@/hooks/tools";
import { useStore } from "vuex";
import { onUnmounted } from '@vue/runtime-core';
export default {
  setup() {
    const store = useStore();

    const GoRegister = () => {
      router.push({ path: "/register" });
    };

    let LoginViewModelAdd = reactive({
      CompanyNameAbbre: "",
      UserName: "",
      Password: "",
    });

    let activeInput = ref("");

    let changeActiveInput = (value) => {
      setTimeout(() => {
        activeInput.value = value;
      }, 0);
    };

    let whetherSaveData = ref(true);

    let userList = reactive(getUserInfo() || []);

    const fillUserInfo = (index) => {
      LoginViewModelAdd.CompanyNameAbbre = userList[index]?.CompanyNameAbbre;
      LoginViewModelAdd.UserName = userList[index]?.UserName;
      LoginViewModelAdd.Password = userList[index]?.Password;
    };

    //初始化页面操作
    if (store.state.regisData) {
      LoginViewModelAdd.CompanyNameAbbre = store.state.regisData?.CompanyNameAbbre
      LoginViewModelAdd.UserName = store.state.regisData?.UserName
      LoginViewModelAdd.Password = store.state.regisData?.Password
    }
    else {
      if (userList) {
        LoginViewModelAdd.CompanyNameAbbre = userList[0]?.CompanyNameAbbre;
        LoginViewModelAdd.UserName = userList[0]?.UserName;
        LoginViewModelAdd.Password = userList[0]?.Password;
      }
    }


    //登录
    let LoginAdd = () => {
      store.commit('clearToken')
      
      const checkResult = loginInitializeCheck(LoginViewModelAdd);
      if (!checkResult) {
        return false;
      }
      loginInitialize(checkResult, whetherSaveData.value);
    };
    

    let enterLogin = (e) => {
      if (e.keyCode == 13) { LoginAdd() }
    }
    window.addEventListener('keyup', enterLogin);
    //离开清理
    onUnmounted(() => {
      let blank = '';
      store.commit('fillRegis', blank)
      window.removeEventListener('keyup', enterLogin)
    })

    return {
      GoRegister,
      LoginViewModelAdd,
      whetherSaveData,
      LoginAdd,
      userList,
      activeInput,
      changeActiveInput,
      fillUserInfo,
    };
  },
  components: {
    LoginStyle1,
    DropDownClick,
  },
};
</script>

<style scoped lang="less">
.logincontent {
  position: relative;
  float: right;
  width: 90%;
  height: 100%;
  background-color: transparent;
}
h6 {
  line-height: 400%;
  color: #a6a6a6;
  font-size: 1.2rem;
}
em {
  color: #6cdaf7;
  font-size: 1.2rem;
}
input,
select {
  margin-top: 0.8rem;
  width: 80%;
  height: 2.3rem;
  font-size: 1rem;
  color: #455171;
  font-weight: 700;
  text-indent: 10px;
  line-height: 200%;
}
#ProductType option {
  font-size: 1.2rem;
}

label {
  color: #cccccc;
  font-size: 1rem;
}

.logincontent div {
  margin-top: 1.25rem;
}
input[type="checkbox"] {
  width: 0.95rem;
  height: 0.95rem;
  vertical-align: bottom;
  margin-right: 5px;
}
.checkAfter {
  vertical-align: middle;
  line-height: 0.95rem;
  padding-bottom: 2px;
  color: #545454;
  font-weight: normal;
  cursor: pointer;
  font-size: 0.75rem;
}
.loginsubmit {
  background-color: #152449;
  cursor: pointer;
  border: none;
  color: #fff;
}
.loginsubmit:hover {
  transform: translateY(-1px);
}

.logincontent p {
  display: inline-block;
  font-size: 1rem;
  margin-right: 2.86rem;
  cursor: pointer;
  font-weight: 700;
}
.LeftLogin {
  position: relative;
  box-sizing: border-box;
  color: #152449;
}
.LeftLogin::after {
  display: block;
  width: 1.13rem;
  border-bottom: 4px solid #152449;
  position: absolute;
  content: "";
  left: 0.5rem;
  top: 1.69rem;
}

.logincontent p:hover {
  position: relative;
  box-sizing: border-box;
  color: #152449;
}

.logincontent p:hover::after {
  display: block;
  width: 1.12rem;
  border-bottom: 4px solid #152449;
  position: absolute;
  content: "";
  left: 0.5rem;
  top: 1.69rem;
}

.contentContainer {
  width: 100%;
  position: relative;
}
.copyRight {
  position: absolute;
  bottom: -3rem;
  left: -30rem;
  color: #5e5e61;
  font-size: .8rem;

}
</style>
