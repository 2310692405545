<template>
  <div class="dropdown-Container" :style="[{'top':topVa},{'width':widthVa}]">
    <div class="dropdown-content" v-for="(value,index) in dropdown" :key="value" @mousedown="fillUserInfo(index)">{{value.CompanyNameAbbre}} {{value.UserName}}</div>
  </div>
</template>

<script>
export default {
  props:['dropDownContent','topValue','widthValue'],
  setup(props,ctx){
      let dropdown = props.dropDownContent
      let topVa = props.topValue
      let widthVa = props.widthValue

      const fillUserInfo = (index)=>{
        ctx.emit('handle',index)
      }

      return{
        dropdown,
        topVa,
        widthVa,
        fillUserInfo
      }
  }
}
</script>

<style scoped lang="less">
.dropdown-Container {
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 6px;
  z-index: 1;
}
.dropdown-content {
  padding-bottom: 0.5rem;
  font-size: 1rem;
}

.dropdown-content:hover {
  background-color: #f5f5f6;
  cursor: pointer;
  
}
</style>